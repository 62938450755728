import lottie from 'lottie-web';
import { Color, Uniform } from './three.module';

import {
  map,
  start,
  limit,
  scale,
  rotate,
  handle,
  reduce,
  forEach,
  opacity,
  addClass,
  checkClass,
  linearRamp,
  removeClass,
  setTabIndex,
  easeInCubic,
  easeOutCubic,
  getWidthRatio,
  easeInOutSine,
  scrollProgress,
  elementProgress,
} from './utils';
import gl from './gl';

import heroShader from './hero.frag';
import cardShader from './cards.frag';
import levelsShader from './levels.frag';
import conceptShader from './concept.frag';
import backgroundShader from './background.frag';

import './style.scss';

let portrait = innerWidth <= innerHeight;
const { log } = console;

const resizeHandlers = [
  function () {
    if (innerWidth <= innerHeight && !portrait) {
      portrait = true;
    } else if (innerWidth > innerHeight && portrait) {
      portrait = false;
    }
  }
];

const sections = {
  hero: {
    element: document.querySelector('#hero'),
  },
  benefits: {
    element: document.querySelector('#benefits'),
  },
  app: {
    element: document.querySelector('.app-wrapper'),
  },
  levels: {
    element: document.querySelector('.levels-cards-wrapper'),
  },
  concept: {
    element: document.querySelector('#concept'),
  },
};

window.addEventListener('scroll', scroll);
window.addEventListener('resize', handle(resizeHandlers));
forEach([hero, benefits, app, levels, outlets, faq, concept], start);

window.addEventListener('load', function () {
  removeClass('loading', document.body);
  window.dispatchEvent(new Event('resize'));
});

function scroll() {
  for (let section in sections) {
    if (typeof sections[section].scroll !== 'function') continue;
    sections[section].scroll(elementProgress(sections[section].element));
  }
}

function setResizeHandler(handler) {
  return handler instanceof Function
    ? resizeHandlers.push(handler)
    : setResizeHandler;
}

function hero() {
  const canvas = document.querySelector('.hero-blob');
  const blob = gl({ canvas, shader: heroShader, setResizeHandler });
  sections.hero.scroll = scroll;

  const appAnimation = lottie.loadAnimation({
    container: document.querySelector('.hero-animation .hero-car'),
    path: 'hero.json',
    renderer: 'svg',
    autoplay: true,
    name: 'hero',
    loop: true,
  });

  function scroll(progress) {
    progress <= 1
      ? !blob.isPlaying && blob.start()
      : blob.isPlaying && blob.stop()
  }
}

function benefits() {
  const wrapper = document.querySelector('.benefits-wrapper');
  const cards = wrapper.querySelectorAll('.card');
  const animations = map(cards, makeAnimation);
  const transitions = [NaN, NaN, NaN];
  sections.benefits.scroll = scroll;

  function scroll(progress) {
    progress >= -2 && progress <= 1
      ? forEach(animations, start)
      : forEach(animations, stop)
  }

  function makeAnimation(card, index) {
    card.addEventListener('mouseenter', enter(index));
    card.addEventListener('mouseleave', leave(index));

    return gl({
      setResizeHandler,
      shader: cardShader,
      timeShift: Math.pow(4, Math.random() * 9 + 1),
      canvas: card.querySelector('.card-blob'),
      uniforms: {
        scale: new Uniform(0),
        color: new Uniform(new Color(0xD3DFFF)),
      },
    });
  }

  function enter(index) {
    return function ({ timeStamp }) {
      const id = Math.floor(timeStamp);
      const old = animations[index].uniforms.scale.value;
      transitions[index] = id;

      linearRamp(function (value) {
        if (transitions[index] !== id) return;

        animations[index].uniforms.scale.value = easeInCubic(value) * (1 - old) + old;
      }, 1000 * (1 - old));
    }
  }

  function leave(index) {
    return function ({ timeStamp }) {
      const id = Math.floor(timeStamp);
      const old = animations[index].uniforms.scale.value;
      transitions[index] = id;

      linearRamp(function (value) {
        if (transitions[index] !== id) return;

        animations[index].uniforms.scale.value = (1 - easeOutCubic(value)) * old;
      }, 1000 * old);
    }
  }

  function start(animation) {
    animation &&
    !animation.isPlaying &&
    animation.start()
  }

  function stop(animation) {
    animation &&
    animation.isPlaying &&
    animation.stop()
  }
}

function app() {
  const rotations = [360, -760, 720];
  const wrapper = document.querySelector('.app-wrapper');
  const container = document.querySelector('.app-animation');
  const screens = document.querySelectorAll('.app-screen');
  const orbits = document.querySelectorAll('.app-orbit-group');
  const orbitsRotationsMap = new Map(reduce(orbits, mapRotationsToOrbits, []));
  const pins = document.querySelectorAll('.app-map-pin');
  const appDevice = document.querySelector('.app-device');
  const appMap = document.querySelector('.app-map');
  const appMapSearch = document.querySelector('.app-map-search');
  const appMapBtn = document.querySelector('.app-map-btn');
  const mapBtnBlue = document.querySelector('.app-map-btn-blue');
  const mapBtnText = document.querySelectorAll('.app-map-btn-text');
  const appProgressWrapper = wrapper.querySelector('.progress-wrapper');
  const appProgressBar = wrapper.querySelector('.progress-bar');
  sections.app.scroll = scroll;

  let sectionProgress = scrollProgress({ wrapper, container });
  let appeared = sectionProgress > 0;
  let currentScreen = 0;

  window.addEventListener('resize', setViewBox);

  function setViewBox() {
    if (innerWidth <= 575) {
      appDevice.setAttribute("viewBox", "0 0 414 896");
    } else {
      appDevice.setAttribute("viewBox", "0 0 289 563");
    }
  }

  if (appeared) {
    scaleOrbits();
    animateMap();
    updateProgress(sectionProgress);
  }

  function mapRotationsToOrbits(accumulator, item, index) {
    accumulator.push([item, rotations[index]]);
    return accumulator;
  }

  function scroll(value) {
    const progress = value * 3 / 2;

    if (appeared) {
      if (progress === sectionProgress) return;

      sectionProgress = progress;
      updateProgress(progress);
    } else if (progress > -.1) {
      appeared = true;
      scaleOrbits();
      animateMap();
    }
  }

  function updateProgress(progress) {
    const newScreen = getScreen(progress);

    if (progress < 0.1) {
      removeClass('show', appProgressWrapper);
      removeClass('slide-0', appProgressBar);

    } else if (progress >= 0.1 && progress <= 0.8) {
      addClass('show', appProgressWrapper);
      addClass('slide-0', appProgressBar);

    } else if (progress > 0.8) {
      removeClass('show', appProgressWrapper);
    }

    if (newScreen !== currentScreen) {
      currentScreen = newScreen;

      forEach(screens, removeClass('show'));
      addClass('show', screens[currentScreen]);

      forEach(pins, removeClass('animated'));

      forEach(mapBtnText, removeClass('show'));
      addClass('show', mapBtnText[currentScreen]);


      if (currentScreen === 0) {
        forEach(pins, addClass('animated'));

        mapBtnBlue.style.width = '186px';
        updateProgressBar(0);
      }

      if (currentScreen === 1) {
        appMapBtn.style.opacity = 1;
        mapBtnBlue.style.width = '382px';
        updateProgressBar(1);
      }

      if (currentScreen === 2) {
        mapBtnBlue.style.width = '382px';
        updateProgressBar(2);
      }
    }

    const limitedProgress = limit(progress, 0, 1);

    orbitsRotationsMap.forEach(function (rotation, element) {
      rotate(limitedProgress * rotation, element);
      forEach(element.children, function (child) {
        child.tagName === 'g' && rotate(-limitedProgress * rotation, child);
      });
    });
  }

  function animateMap() {
    addClass('animated', appMap);
    addClass('animated', appMapSearch);
    addClass('animated', appMapBtn);
    forEach(pins, addClass('animated'));
  }

  function scaleOrbits() {
    linearRamp(setScale, 1200);

    function setScale(value) {
      scale(limit(value * 1.2, 0, 1), orbits[0])
      scale(limit(value * 1.2 - .1, 0, 1), orbits[1])
      scale(limit(value * 1.2 - .2, 0, 1), orbits[2])
    }
  }

  function getScreen(value) {
    return value < 1 / 3
      ? 0
      : value < 1 / 3 * 2
        ? 1
        : 2
  }

  function updateProgressBar(slide) {
    removeClass(`slide-${slide + 1}`, appProgressBar);

    if (!Number.isFinite(slide)) return;

    if (slide >= 0) {
      addClass(`slide-${slide}`, appProgressBar);
    }
  }
}

function levels() {
  const wrapper = sections.levels.element;
  const cards = wrapper.querySelectorAll('.levels-card');
  const animations = document.querySelectorAll('.levels-animation');
  const closeButtons = wrapper.querySelectorAll('.levels-close');
  const levelButtons = wrapper.querySelectorAll('.levels-link');
  const levelProgressWrapper = document.querySelector('.levels-progress');
  const levelProgressBar = document.querySelector('.levels-progress-bar');
  const hash = parseInt(location.hash.slice(-1));
  const classes = {
    body: 'c-main',
    card: 'levels-card-active',
    animation: 'levels-animation-active',
    expanded: 'levels-card-expanded',
    concept: 'concept-container-visible',
  };

  let sectionProgress = elementProgress(wrapper);
  let flooded = false;
  let expanded = false;
  let scrolling = false;
  let level = hash ? hash - 1 : 0;

  const blob = gl({
    canvas: document.querySelector('.levels-canvas'),
    shader: levelsShader,
    setResizeHandler,
    uniforms: {
      shrink: new Uniform(0),
      progress: new Uniform(limit(sectionProgress, 0, 1)),
      flood: new Uniform(0),
      color: new Uniform(new Color(0xD3DFFF)),
      portrait: new Uniform(portrait),
    },
  });

  const background = gl({
    canvas: document.querySelector('.background-canvas'),
    shader: backgroundShader,
    setResizeHandler,
    initialState: false,
    uniforms: {
      level: new Uniform(0),
      width: new Uniform(.5),
      left: new Uniform(false),
      color: new Uniform(new Color(0xD3DFFF)),
      portrait: new Uniform(portrait),
    },
  });

  sections.levels.scroll = scroll;

  scroll(sectionProgress);
  resizeHandlers.push(resize);
  forEach(levelButtons, open);
  forEach(closeButtons, close);
  forEach(cards, waitForCardToExpand);

  function resize() {
    expanded && cards[level].scrollIntoView(true);
    background.uniforms.width.value = getWidthRatio(level % 2 !== 0, cards[level]) * devicePixelRatio;

    if (portrait !== blob.uniforms.portrait.value) {
      blob.uniforms.portrait.value = portrait;
      background.uniforms.portrait.value = portrait;
    }
  }

  function scroll(progress) {
    if (progress < -1 / 3) {
      stopBlob();

    } else if (progress <= 1 / 3 * 2) {
      startBlob();
      addClass(`show`, levelProgressWrapper);

      if (expanded && !scrolling) {
        closeButtons[level].click();
      }

      if (flooded) {
        emptyBackground();
      }

      const limitedProgress = limit(progress * 3 / 2, 0, 1);
      blob.uniforms.progress.value = limitedProgress;

      if (!scrolling) {
        limitedProgress <= .25
          ? (level !== 0 || !checkClass(classes.card, cards[0])) && changeLevel(0)
          : limitedProgress > .25 && limitedProgress <= .75
            ? (level !== 1 || !checkClass(classes.card, cards[1])) && changeLevel(1)
            : limitedProgress > .75 && limitedProgress <= .99
              ? (level !== 2 || !checkClass(classes.card, cards[2])) && changeLevel(2)
              : changeLevel();
      }

      if (!scrolling) {
        if (limitedProgress <= .01) {
          updateProgressBar(-1);
          removeClass(`show`, levelProgressWrapper);

        } else if (limitedProgress > .01 && limitedProgress <= .25) {
          updateProgressBar(0);

        } else if (limitedProgress > .25 && limitedProgress <= .75) {
          updateProgressBar(1);

        } else if (limitedProgress > .75 && limitedProgress <= .99) {
          updateProgressBar(2);

        } else {
          updateProgressBar();
        }
      }
    } else if (progress > 1 / 3 * 2) {
      if (progress <= .69) {
        startBlob();
        blob.uniforms.progress.value = progress * 3 / 2;
        expanded && !scrolling && closeButtons[level].click();

        changeLevel(2);

        if (flooded) {
          addClass(classes.card, cards[2]);
          emptyBackground();
        }
      } else if (!flooded) {
        startBlob();
        removeClass(classes.card, cards[2]);
        floodBackground();
        changeLevel();
      }

      removeClass(`show`, levelProgressWrapper);
    }
  }

  function floodBackground() {
    if (flooded) return;

    flooded = true;
    linearRamp(flood, 1000);

    function flood(value) {
      blob.uniforms.flood.value = easeInOutSine(value);

      if (value === 1) {
        blob.stop();
      }
    }
  }

  function emptyBackground() {
    if (!flooded) return;

    flooded = false;
    linearRamp(empty, 750);

    function empty(value) {
      blob.uniforms.flood.value = 1. - easeInOutSine(value);
    }
  }

  function showCardBackground() {
    linearRamp(show, 1000);

    function show (value) {
      const progress = easeInOutSine(value);
      blob.uniforms.shrink.value = progress;
      background.uniforms.level.value = progress;
    }
  }

  function hideCardBackground() {
    linearRamp(hide, 1000);

    function hide(value) {
      const progress = 1 - easeInOutSine(value);
      blob.uniforms.shrink.value = progress;
      background.uniforms.level.value = progress;
      value === 1 && requestAnimationFrame(stopBackground);
    }
  }

  function changeLevel(newLevel, withBackground) {
    forEach(cards, removeClass(classes.card))
    forEach(animations, removeClass(classes.animation))

    if (!Number.isFinite(newLevel)) return;

    level = newLevel;
    addClass(classes.animation, animations[newLevel]);
    addClass(classes.card, cards[newLevel]);

    if (withBackground) {
      startBackground();
      background.uniforms.left.value = newLevel % 2 !== 0;
      showCardBackground();

      addClass(classes.expanded, cards[newLevel]);
      setTabIndex(0, closeButtons[newLevel]);

      const { offsetTop } = wrapper;
      document.documentElement.scrollTo({ top: offsetTop + innerHeight * newLevel, behavior: 'smooth' });

      const full = cards[newLevel].children[4];
      full.style.maxHeight = full.scrollHeight + 'px';
    }
  }

  function updateProgressBar(level) {
    removeClass(`level-${level + 1}`, levelProgressBar);

    if (!Number.isFinite(level)) return;

    if (level >= 0) {
      addClass(`level-${level}`, levelProgressBar);
    }
  }

  function open(button, index) {
    function click(event) {
      event.preventDefault();

      const oldLevel = level;
      const newLevel = index;

      if (isFinite(newLevel)) {
        scrolling = true;
        expanded && closeButtons[oldLevel].click();
        changeLevel(newLevel, true);
      }
    }

    return button instanceof HTMLElement
      ? button.addEventListener('click', click)
      : close;
  }

  function close(button) {
    function click(event) {
      event.preventDefault();
      button.blur();
      expanded = false;
      setTabIndex(-1, button);
      removeClass(classes.expanded, button.parentElement);
      button.parentElement.children[4].style.maxHeight = '';

      if (!scrolling) {
        hideCardBackground();
        addClass(classes.card, button.parentElement);
        history.replaceState(null, null, ' ');
      }
    }

    return button instanceof HTMLElement
      ? button.addEventListener('click', click)
      : close;
  }

  function waitForCardToExpand(card) {
    function transitionend({ propertyName }) {
      if (propertyName === 'max-height' && /expanded/.test(card.className)) {
        expanded = true;
        scrolling && (scrolling = false);
      }
    }

    return card instanceof HTMLElement
      ? card.querySelector('.levels-full').addEventListener('transitionend', transitionend)
      : waitForCardToExpand;
  }

  function stopBackground() {
    background.isPlaying && background.stop();
  }

  function startBackground() {
    !background.isPlaying && background.start();
  }

  function stopBlob() {
    blob.isPlaying && blob.stop();
  }

  function startBlob() {
    !blob.isPlaying && blob.start();
  }
}

function outlets() {
  const indicator = document.querySelector('.outlets-labels-indicator');
  const radios = document.querySelectorAll('input[name="outlet"]');
  const labels = document.querySelectorAll('.outlets-label');
  const cards = document.querySelector('.outlets-cards-wrapper');

  let currentLevel = 0;

  forEach(labels, addLabelsListener);
  setResizeHandler(moveIndicatorOnResize);
  cards.addEventListener('touchstart', touchstart);

  function changeLevel(newLevel, moveCards = true) {
    currentLevel = newLevel;
    moveIndicator(newLevel);
    moveCards && (cards.style.transform = 'translateX(-' + (newLevel * cards.getBoundingClientRect().width / 3) + 'px)')
  }

  function moveIndicatorOnResize() {
    moveIndicator(currentLevel);
     if (cards.style.transform) {
       cards.style.transform = 'translateX(-' + (currentLevel * cards.getBoundingClientRect().width / 3) + 'px)';
     }
  }

  function addLabelsListener(label, index) {
    radios[index].checked && moveIndicator(index);
    radios[index].addEventListener('change', change);

    function change() {
      changeLevel(index);
    }
  }

  function touchstart(event) {
    const { width } = cards.getBoundingClientRect();
    const cardWidth = width / 3;

    let startX = event.touches[0].pageX;
    let level = currentLevel;
    let maxBreakpoint = .15;
    let minBreakpoint = -maxBreakpoint;

    removeClass('animating', cards);
    cards.style.transform = 'translateX(-' + (level * cardWidth) + 'px)';
    document.addEventListener('touchmove', touchmove);
    document.addEventListener('touchend', touchend, { once: true });

    function touchmove({ touches }) {
      let shift = startX - touches[0].pageX;

      if (
        (level === 0 && shift < 0) ||
        (level === 2 && shift > 0) ||
        startX === touches[0].pageX
      ) return;

      const cardShift = shift / cardWidth;

      if (cardShift > maxBreakpoint) {
        level += 1;
        startX -= cardWidth;
        minBreakpoint = -.85;
        radios[level].checked = true;
        changeLevel(level, false);
      } else if (cardShift < minBreakpoint) {
        level -= 1;
        startX += cardWidth;
        maxBreakpoint = .85;
        radios[level].checked = true;
        changeLevel(level, false);
      } else if (cardShift > -.1 && cardShift < .1) {
        minBreakpoint !== -.15 && (minBreakpoint = -.15);
        maxBreakpoint !== .15 && (maxBreakpoint = .15);
      }

      shift = startX - touches[0].pageX;

      const progress = shift / cardWidth + level;

      if (progress <= 1) {
        cards.children[0].style.opacity = (1 - progress).toString();
        cards.children[1].style.opacity = (progress).toString();
      } else {
        cards.children[1].style.opacity = (2 - progress).toString();
        cards.children[2].style.opacity = (progress - 1).toString();
      }

      cards.style.transform = 'translateX(-' + (shift + level * cardWidth) + 'px)';
    }

    function touchend() {
      document.removeEventListener('touchmove', touchmove);
      addClass('animating', cards);
      forEach(cards.children, function (card) {
        card.style.opacity = '';
      });
      cards.style.transform = 'translateX(-' + (level * cardWidth) + 'px)';
      currentLevel = level;
    }
  }

  function moveIndicator(index) {
    const { offsetLeft, offsetWidth } = labels[index];
    indicator.style.width = offsetWidth + 'px';
    indicator.style.transform = 'translateX(' + offsetLeft + 'px)';
  }
}

function faq() {
  const items = document.querySelectorAll('.faq-item-label');
  const answers = document.querySelectorAll('.faq-answer');
  const paths = {
    true: { from: 'M11 17L17 17L23 17', to: 'M11 17L17 10.5L23 17' },
    false: { from: 'M11 17L17 10.5L23 17', to: 'M11 17L17 17L23 17' },
  };

  resizeHandlers.push(resize);

  forEach(items, function (item, index) {
    item.firstElementChild.addEventListener('change', function ({ target: { checked } }) {
      resize();

      const arrow = items[index].querySelector('.faq-icon-arrow');
      const animate = document.createElementNS('http://www.w3.org/2000/svg', 'animate');

      if (typeof animate.beginElement !== 'function') {
        arrow.setAttributeNS(null, 'd', paths[checked].to);
        return;
      }

      animate.setAttributeNS(null, 'attributeName', 'd');
      animate.setAttributeNS(null, 'dur', '0.2s');
      animate.setAttributeNS(null, 'begin', 'indefinite');
      animate.setAttributeNS(null, 'fill', 'freeze');
      animate.setAttributeNS(null, 'from', paths[checked].from);
      animate.setAttributeNS(null, 'to', paths[checked].to);
      arrow.firstElementChild && arrow.firstElementChild.remove();
      arrow.appendChild(animate);
      animate.beginElement();
    });
  });

  function resize() {
    forEach(answers, function (answer, index) {
      items[index].firstElementChild.checked
        ? !answer.style.height && (answer.style.height = 22 + answer.scrollHeight + 'px')
        : answer.style.height = '';
    })
  }
}

function concept() {
  const container = document.querySelector('.concept-container');
  const classes = { visible: 'concept-container-visible' };
  let visible = false;

  const animation = gl({
    canvas: document.querySelector('.concept-canvas'),
    shader: conceptShader,
    setResizeHandler,
    initialState: false,
    uniforms: {
      portrait: new Uniform(portrait),
      progress: new Uniform(0),
      color: new Uniform(new Color(0xD3DFFF)),
    },
  });

  sections.concept.scroll = scroll;
  resizeHandlers.push(resize);

  function scroll(progress) {
    if (progress < -.1) {
      visible && hide();
    } else {
      !visible && show();
    }
  }

  function resize() {
    if (portrait !== animation.uniforms.portrait.value) {
      animation.uniforms.portrait.value = portrait;
    }
  }

  function show() {
    !animation.isPlaying && animation.start();
    visible = true;
    linearRamp(fill, 800);
    addClass(classes.visible, container);

    function fill(value) {
      if (!visible) return;

      animation.uniforms.progress.value = easeOutCubic(value);
    }
  }

  function hide() {
    visible = false;
    linearRamp(empty, 800);
    removeClass(classes.visible, container);

    function empty(value) {
      if (visible) return;

      animation.uniforms.progress.value = 1. - easeInCubic(value);

      if (value === 1) {
        requestAnimationFrame(stop);
      }
    }
  }

  function stop() {
    animation.isPlaying && animation.stop();
  }
}
