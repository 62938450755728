export function start(func) {
  return func instanceof Function
    ? func()
    : start;
}

export function isElement(element) {
  return element instanceof HTMLElement || element instanceof SVGElement;
}

export function handle(handlers) {
  return function(e) {
    forEach(handlers, function (prop) {
      prop instanceof Function && prop(e);
    });
  }
}

export function forEach(array, callback, scope) {
  for (let i = 0; i < array.length; i++) {
    callback.call(scope, array[i], i);
  }
}

export function map(array, callback, scope) {
  const result = Array(array.length);

  for (let i = 0; i < array.length; i++) {
    result[i] = callback.call(scope, array[i], i);
  }

  return result;
}

export function reduce(array, callback, accumulator, scope) {
  for (let i = 0; i < array.length; i++) {
    accumulator = callback.call(scope, accumulator, array[i], i);
  }

  return accumulator;
}

export function find(array, callback, scope) {
  for (let i = 0; i < array.length; i++) {
    const result = callback.call(scope, array[i], i);
    if (result) return array[i];
  }
}

export function limit(value, min, max) {
  return value < min
    ? min
    : value > max
      ? max
      : value;
}

export function checkClass(className, element) {
  function tryAgain(newElement) {
    return isElement(newElement)
      ? checkClass(className, newElement)
      : tryAgain;
  }

  return isElement(element)
    ? element.classList.contains(className)
    : tryAgain;
}

export function addClass(className, element) {
  function tryAgain(newElement) {
    return isElement(newElement)
      ? addClass(className, newElement)
      : tryAgain;
  }

  function add(c) {
    element.classList.add(c);
  }

  return isElement(element)
    ? typeof className === 'string'
      ? add(className)
      : Array.isArray(className) && forEach(className, add)
    : tryAgain;
}

export function removeClass(className, element) {
  function tryAgain(newElement) {
    return isElement(newElement)
      ? removeClass(className, newElement)
      : tryAgain;
  }

  function remove(c) {
    element.classList.remove(c);
  }

  return isElement(element)
    ? typeof className === 'string'
      ? remove(className)
      : Array.isArray(className) && forEach(className, remove)
    : tryAgain;
}

export function elementProgress(props) {
  function calc({ top, height }) {
    return (-1 * top) / (height);
  }

  return isElement(props)
    ? calc(props.getBoundingClientRect())
    : props.top && props.height
      ? calc(props)
      : elementProgress;
}

export function scrollProgress({ wrapper, container }) {
  const { offsetTop, offsetHeight } = container;
  return offsetTop / (wrapper.offsetHeight - offsetHeight);
}

export function setTabIndex(tabIndex, element) {
  function tryAgain(element) {
    return isElement(element)
      ? element.tabIndex = tabIndex
      : tryAgain
  }

  return isElement(element)
    ? element.tabIndex = tabIndex
    : tryAgain;
}

export function getWidthRatio(isOdd, element) {
  function calculate(element) {
    const { left, width } = element.getBoundingClientRect();
    const cardOffsetWidth = isOdd ? left + width : innerWidth - left;
    return cardOffsetWidth / innerWidth / devicePixelRatio * 2;
  }

  return isElement(element)
    ? calculate(element)
    : calculate;
}

export function linearRamp(callback, duration = 500) {
  function ramp() {
    let progress = 0;
    let start = NaN;
    requestAnimationFrame(step);

    function step(timeStamp) {
      if (!start) start = timeStamp;

      progress = (timeStamp - start) / duration;

      if (progress > 1) {
        callback(1);
      } else {
        callback(progress);
        requestAnimationFrame(step);
      }
    }
  }

  return callback instanceof Function
    ? ramp()
    : linearRamp;
}

export function easeOutCubic(x) {
  return 1 - Math.pow(1 - x, 3);
}

export function easeInCubic(x) {
  return Math.pow(x, 3);
}

export function easeInOutCubic(x) {
  return x < 0.5
    ? 4 * Math.pow(x, 3)
    : 1 - Math.pow(-2 * x + 2, 3) / 2;
}

export function easeInOutSine(x) {
  return -(Math.cos(Math.PI * x) - 1) / 2;
}

export function easeOutCircular(x) {
  return Math.sqrt(1 - Math.pow(x - 1, 2));
}

export function easeInCircular(x) {
  return 1 - Math.sqrt(1 - Math.pow(x, 2));
}

export function rotate(degrees, element) {
  function setRotation(degrees, absolutelyElement) {
    const { transform } = element.style;
    absolutelyElement.style.transform = /rotate/.test(transform)
      ? transform.replace(/rotate\([0-9.\-]+(?=(deg|turn)\))/g, 'rotate(' + degrees.toString())
      : `rotate(${ degrees }deg)${ transform ? ' ' + transform : '' }`;
  }

  function tryAgain(anotherElement) {
    return isElement(anotherElement)
      ? setRotation(degrees, anotherElement)
      : tryAgain;
  }

  return isElement(element)
    ? setRotation(degrees, element)
    : tryAgain;
}

export function scale(scale, element) {
  function setScale(absolutelyElement) {
    const { transform } = element.style;
    absolutelyElement.style.transform = /scale/.test(transform)
      ? transform.replace(/scale\([0-9.\-]+(?=\))/g, 'scale(' + scale.toString())
      : `scale(${ scale })${ transform ? ' ' + transform : '' }`;
  }

  function tryAgain(anotherElement) {
    return isElement(anotherElement)
      ? setScale(anotherElement)
      : tryAgain;
  }

  return isElement(element)
    ? setScale(element)
    : tryAgain;
}

export function opacity(value, element) {
  function set(value, element) {
    const { style: { opacity }} = element;
    const newOpacity = value.toString()
    opacity !== newOpacity && (element.style.opacity = newOpacity);
  }

  function tryAgain(element) {
    return isElement(element)
      ? set(value, element)
      : tryAgain;
  }

  return isElement(element)
    ? set(value, element)
    : tryAgain;
}
