import {
  Mesh,
  Scene,
  Uniform,
  Vector2,
  WebGLRenderer,
  ShaderMaterial,
  OrthographicCamera,
  PlaneBufferGeometry,
} from './three.module';

export default function gl({ canvas, uniforms = {}, shader, setResizeHandler, initialState = true, timeShift = 0 }) {
  uniforms.time = new Uniform(0);
  uniforms.resolution = new Uniform(new Vector2(1, 1));

  const scene = new Scene();
  const camera = new OrthographicCamera(- 1, 1, 1, - 1, 0, 1);
  const renderer = new WebGLRenderer({ canvas, antialias: true, alpha: true });
  const material = new ShaderMaterial({ uniforms, fragmentShader: shader });
  const geometry = new PlaneBufferGeometry(2, 2);
  const mesh = new Mesh(geometry, material);

  const self = {
    stop,
    start,
    canvas,
    uniforms,
    isPlaying: initialState,
  }

  scene.add(mesh);
  resize();
  setResizeHandler(resize);
  let animation = initialState ? requestAnimationFrame(animate) : NaN;

  function resize() {
    const { clientWidth, clientHeight } = canvas;
    const width = clientWidth * devicePixelRatio;
    const height = clientHeight * devicePixelRatio;
    uniforms.resolution.value = new Vector2(width, height);
    renderer.setSize(width, height, false);
    !self.isPlaying && renderer.render(scene, camera);
  }

  function animate(timestamp) {
    if (!self.isPlaying) return;

    uniforms.time.value = timestamp + timeShift;
    animation = requestAnimationFrame(animate);
    renderer.render(scene, camera);
  }

  function start() {
    self.isPlaying = true;
    animation = requestAnimationFrame(animate);
  }

  function stop() {
    self.isPlaying = false;
    cancelAnimationFrame(animation);
  }

  return self;
}
